import React from "react"
import { graphql } from "gatsby"
import { Box, Flex, Grid, Heading, Text as TText } from "theme-ui"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Intro from "../components/Intro"
import Pathway from "../components/Pathway"
import PageContainer from "../components/PageContainer"
import Analysis from "../images/analysis.svg"

import { motion } from "framer-motion"

export const query = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allOpenGraphData {
      nodes {
        title
        url
        description
        site_name
      }
    }
    posts: allMdx(sort: { order: DESC, fields: fileAbsolutePath }) {
      nodes {
        frontmatter {
          slug
          title
          illustration {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        id
        excerpt(pruneLength: 140)
      }
    }
  }
`
const IndexPage = ({
  data: { posts, ogImage, bolan, ranta, allOpenGraphData },
}) => {
  // const {
  //   mount,
  //   current,
  //   exit,
  //   entry,
  //   transitionStatus,
  //   ...rest
  // } = useTransitionState()
  return (
    <motion.div
    // initial={entry.state}
    // animate={
    //   transitionStatus === "exiting" ? exit.state : { opacity: 1, x: 0, y: 0 }
    // }
    // transition={
    //   transitionStatus === "exiting"
    //     ? { durration: exit.length }
    //     : { durration: 0.4 }
    // }
    >
      <Layout>
        <SEO title={`Vanliga frågor om bolån`} />
        <PageContainer>
          <Intro />
          {/* <Box sx={{ marginY: 4 }}>
        <BolanKostnad></BolanKostnad>
      </Box> */}
        </PageContainer>
        <Box sx={{ backgroundColor: "gray.2", paddingY: 4 }}>
          <PageContainer>
            <Grid columns={[1, 2, 3]}>
              {posts.nodes.map((node, index) => (
                <Pathway
                  key={index}
                  title={node.frontmatter.title}
                  description={node.excerpt}
                  to={node.frontmatter.slug}
                  image={node.frontmatter.illustration.childImageSharp.fluid}
                />
              ))}
            </Grid>
          </PageContainer>
        </Box>
        <PageContainer>
          <Flex
            sx={{
              flexDirection: ["column", "row"],
            }}
          >
            <Box sx={{ maxWidth: "600px", marginTop: [0, 3] }}>
              <Heading
                as={`h2`}
                sx={{
                  fontWeight: "bold",
                  fontSize: [3, 6],
                  marginY: 4,
                  color: "gray.9",
                }}
              >
                {/* Välkommen till Bolån FAQ */}
                Senaste nyheterna om bolån
              </Heading>
            </Box>
            <Box
              as={`img`}
              alt={`Bolån FAQ Intro bild`}
              width="192px"
              height="192px"
              sx={{
                height: "48",
                width: "48",
                marginX: 4,
                flexShrink: 0,
                aspectRatio: "1/1",
                alignSelf: "center",
              }}
              src={Analysis}
            ></Box>
          </Flex>
        </PageContainer>
        <Box sx={{ backgroundColor: "white", paddingY: 4 }}>
          <PageContainer>
            {allOpenGraphData.nodes.map((node, index) => (
              <Box
                as={"a"}
                key={index}
                href={node.url}
                rel="noopener"
                target={"_blank"}
                sx={{
                  display: "block",
                  boxShadow: "default",
                  backgroundColor: "white",
                  padding: 3,
                  marginY: 3,
                  // borderRadius: "lg",
                  textDecoration: "none",
                  transition: `box-shadow 0.2s ease`,
                  "&:hover": {
                    boxShadow: "lg",
                  },
                }}
              >
                <TText
                  sx={{ fontSize: 1, color: "text", fontWeight: "semibold" }}
                >
                  {node.title}
                </TText>
                <TText sx={{ fontSize: 1, color: "gray.7" }}>
                  {node.description}
                </TText>
                <TText sx={{ fontSize: 0, color: "gray.8" }}>
                  {node.site_name}
                </TText>
              </Box>
            ))}
          </PageContainer>
        </Box>
      </Layout>
    </motion.div>
  )
}

export default IndexPage
