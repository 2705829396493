/** @jsx jsx */
import Image from "gatsby-background-image"
import { Box, Text, jsx } from "theme-ui"
import { Link } from "gatsby"
// import Link from "gatsby-plugin-transition-link"
export default function Pathway({ to, image, title, children, description }) {
  return (
    <Box
      as={Link}
      to={to}
      sx={{
        flexDirection: "column",
        // padding: 4,
        boxShadow: "default",
        backgroundColor: "white",

        // borderRadius: "lg",
        textDecoration: "none",
        transition: `box-shadow 0.2s ease`,
        "&:hover": {
          boxShadow: "lg",
        },
      }}
    >
      <Image
        fluid={image}
        alt={title}
        sx={{
          padding: 3,
          minHeight: "32",
        }}
      >
        <Text
          as={`h2`}
          sx={{
            backgroundColor: "white",
            display: "inline",
            color: "gray.9",
            padding: 2,
            fontSize: [1, null, 2],
            fontWeight: "bold",
            lineHeight: ["normal", "tight", "normal"],
            boxDecorationBreak: "clone",
            whiteSpace: "pre-wrap",
          }}
        >
          {title}
        </Text>
      </Image>
      <Box as={`p`} sx={{ padding: 3, color: "gray.9" }}>
        {description}
      </Box>
    </Box>
  )
}
