import React from "react"
import { Box, Flex, Heading } from "theme-ui"
import Cabin from "../images/cabin.svg"

export default function Intro() {
  return (
    <Flex
      sx={{
        flexDirection: ["column", "row"],
      }}
    >
      <Box sx={{ maxWidth: "600px", marginTop: [0, 3] }}>
        <Heading
          as={`h1`}
          sx={{
            fontFamily: "sans",
            fontWeight: "bold",
            fontSize: [5, 8],
            marginY: 4,
            color: "gray.9",
          }}
        >
          {/* Välkommen till Bolån FAQ */}
          För vanliga frågor kring bolån
        </Heading>
        <Box as={`p`} sx={{ fontSize: [1, 2], color: `gray.7`, marginY: 4 }}>
          Att skaffa ett bolån är ett stort beslut, och kan vara väldigt
          förvirrande och stressigt. Här hittar du konkreta råd och information,
          samt jämförelser mellan olika banker så att du kan vara säker på att
          du väljer rätt när tiden kommer.
        </Box>
      </Box>
      <Box
        as={`img`}
        alt={`Bolån FAQ Intro bild`}
        sx={{
          height: "auto",
          width: "48",
          marginX: 4,
          flexShrink: 0,
          alignSelf: "center",
        }}
        src={Cabin}
        width="192px"
        height="192px"
      ></Box>
    </Flex>
  )
}
